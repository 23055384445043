import React from "react"
import Layout from "../components/layout-contents"
import styles from "../styles/crew.module.css"
import Container from "../components/container"
import {graphql, Link} from "gatsby"


const CrewMember = props => (
  <Link to={props.link} className={styles.crew}>
    <div className={styles.description}>
      <h2 className={styles.title}>{props.title}</h2>
      <h3 className={styles.title}>{props.subtitle}</h3>
      <p className={styles.excerpt}>{props.excerpt}</p>
    </div>
    <img src={props.image} className={styles.image} alt=""/>
  </Link>
)

/*
  <h1>About CSS Modules</h1>
    <p>CSS Modules are cool</p>
*/
export default ({data}) => (
  <Layout>
    <Container>
      <h1 className='page_title'>
        <img className='page_title_image'
             src="/images/home/logo_crew.png"
             alt="crew"
        />
      </h1>

      {data.allCrewJson.edges.map(({node}, i) => (
        <CrewMember
          key={i}
          title={node.title}
          subtitle={node.subtitle}
          excerpt={node.excerpt}
          image={node.image}
          link={node.link}
        />
      ))}
    </Container>
  </Layout>
)

export const query = graphql`
  query {
      allCrewJson {
        edges {
          node {
            title
            subtitle
            excerpt
            image
            link
          }
        }
      }
  }
`
